.AdditionalActivateDialog {
  background: #fff;
  border: none;
  border-radius: 12px;
  max-height: 580px;
  max-width: 580px;
  overflow: hidden;
  width: 580px;
}

.AdditionalActivateDialog :global(.widget-main) {
  padding: 12px 25px;
}

.AdditionalActivateDialog :global(.widget-body) {
  padding: 0 10px 12px 10px;
}

.closeAddonDialogButton {
  background: #333333 !important;
  color: #fff !important;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 321055;
}

.buttonWrappers {
  align-items: center;
  display: flex;
  gap: 35px;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.additionalButtons {
  border-radius: 2px;
  font-weight: 700;
  padding: 8px 18px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.contentWrapper.inDialog {
  max-height: 347px;
}

.AdditionalActivateDialogHeader {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  height: 150px;
  overflow: hidden;
  width: 100%;
}

.AdditionalActivateDialogHeader svg {
  width: 100%;
}

.activateTitle {
  color: #333333;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.headerIcon {
  color: #00a355;
  font-size: 82px !important;
}

.additionalsTable:global(.BngTable .BngTableTd) {
  border: none !important;
}

.additionalsTable:global(.BngTable .BngTableTr:hover .BngTableTd),
.additionalsTable:global(.BngTable .BngTableTr .BngTableTd) {
  background: #fff;
  color: #333333;
  padding: 12px 0;
}

.pricingRow {
  font-size: 18px;
  text-align: right;
  width: 100%;
}

.titleWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
}

.titleWrapper span {
  line-height: 28px;
}

.titleSmallPrint {
  color: #888888;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.newProposalMsg {
  color: #333;
  line-height: 16px;
}

.additionalType {
  width: fit-content;
}

.additionalType.PLAN {
  background: var(--blue-default);
}

.additionalType.ADDITIONAL {
  background: var(--green);
}

.additionalType.SERVICE {
  background: #ffb533;
}

.typeName {
  white-space: nowrap;
}

.acceptRequestButton {
  background: var(--green) !important;
}

.acceptRequestButton:hover {
  filter: brightness(85%) !important;
}

.tableWrapper {
  border-bottom: 1px solid #e4e4e4;
}

.dialogScrollWrapper {
  overflow-y: scroll;
  background: var(--background-scroll-shadow);
}

.detailsWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.detailsBtn {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
}

.hiddenDetails {
  align-items: start;
  border-bottom: 1px solid #e4e4e4;
  color: #555555;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.requestProps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.requestProps > div {
  display: inline-flex;
  flex: 1;
  flex-basis: 50%;
  gap: 5px;
}

.idProp {
  display: flex;
  justify-content: center;
  width: 100%;
}

.subtotalWrapper {
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 12px;
}

.subtotalWrapper div {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.subtotalWrapper span:not(.pricingField) {
  color: #888888;
  font-size: 16px;
}

.totalWrapper {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.pricingField {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
}

.totalWrapper b {
  font-size: 18px;
}

.requestMessageWrapper {
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
}
