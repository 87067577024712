.hidden-filter {
  opacity: 0.5;
}

.user-filter-config-icon .Icon {
  align-items: center;
  display: flex;
  font-size: 15px;
  justify-content: center;
  padding-right: 5px;
}

.DashboardFilter .FilterIconDraggableIcon {
  color: #ccc;
}

.DashboardFilter .nodis {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}

.user-filter-config-icon.ConfigFilterGear {
  vertical-align: top;
  padding-right: 5px;
}

.remove-filter,
.reset-filter-icon {
  align-items: center;
  display: flex;
  height: 100%;
  padding-right: 5px;
}

.reset-filter-icon i,
.remove-filter i {
  font-size: 18px;
  line-height: 18px;
}