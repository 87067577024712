.BngSelectSearch {
    box-shadow: inset 0 0 0 1px #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #555;
    position: relative;
    background-color: white;
}

.control-group.error .BngSelectSearch {
    box-shadow: inset 0 0 0 1px #f09784;
    border-width: 0;
}

.BngSelectSearch.Open {
    border-color: transparent !important;
}

.BngSelectSearch .OptionPreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 6px 4px 12px;
    border-radius: 4px;
    line-height: 30px;
    height: 30px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
}

.BngSelectSearch .OptionPreview label {
    margin: 0;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.BngSelectSearch .OptionPreview .OptionPreviewIcon {
    width: 24px;
}

.BngSelectSearch .BngSelectDropdown {
    top: 0;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.BngSelectSearch .BngSelectDropdown.Grouped > ul {
    padding: 8px 10px 8px 8px;
}

.BngSelectSearch .BngSelectDropdown .bng-search {
    border-bottom: 1px solid #ccc;
    padding: 0 4px;
}

.BngSelectSearch .BngSelectDropdown ul {
    margin: 8px 8px 8px 5px;
}

.BngSelectSearch .BngSelectDropdown > ul {
    max-height: 200px;
    overflow: auto;
}

.BngSelectSearch .Option:not(.EmptyOpt),
.BngSelectSearch .GroupedOptions.DirectOption {
    cursor: pointer;
}

.BngSelectSearch .Option {
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: pre;
    min-height: 1.2em;
    padding: 6px 8px;
}

.BngSelectSearch .Option.Disabled,
.BngSelectSearch .GroupedOptions.Disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.BngSelectSearch .GroupedOptions {
    padding-left: 6px;
}

.BngSelectSearch .GroupedOptions:first-child {
    padding-top: 6px;
}

.BngSelectSearch .GroupedOptions > label {
    display: flex;
    align-items: center;
}


.BngSelectSearch .GroupedOptions {
    margin-bottom: 12px;
}

.BngSelectSearch .GroupedOptions.Expanded,
.BngSelectSearch .GroupedOptions:last-child {
    margin-bottom: 0;
}

.BngSelectSearch .GroupedOptions .OptionGroup {
    cursor: pointer !important;
    font-weight: 500;
}

.BngSelectSearch .GroupedOptions.Expanded > .OptionGroup,
.BngSelectSearch .GroupedOptions.Expanded > .OptionGroup .Icon {
    color: #005DFF;
    opacity: 1;
    margin-bottom: 0;
}

.BngSelectSearch .OptionGroup .Option:first-child {
    padding-top: 12px;
}

.BngSelectSearch .OptionGroup .Option {
    padding: 8px 8px 8px 20px;
    border-left: 1px dashed #005DFF;
    margin-bottom: 1px;
    position: relative;
}

.BngSelectSearch .Option:not(.EmptyOpt):hover {
    background-color: #fafafa;
    color: #005DFF;
}

.BngSelectSearch .GroupedOptions:not(.Disabled):hover .OptionLabel {
    color: #005DFF;
}

.ExpandCircle {
    box-sizing: border-box;
    height: 11px;
    width: 11px;
    border: 1px solid #000000;
    opacity: 0.5;
    border-radius: 8px;
    position: relative;
    text-align: center;
}

.ExpandCircle .Bar {
    box-sizing: border-box;
    height: 1px;
    width: 5px;
    border-top: 1px solid #000000;
    opacity: 0.8;
    position: absolute;
    top: 4px;
    left: 2px;
    transform: rotate(90deg);
}

.ExpandCircle .Line {
    box-sizing: border-box;
    height: 1px;
    width: 5px;
    border-top: 1px solid #000000;
    opacity: 0.8;
    position: absolute;
    top: 4px;
    left: 2px;
}

.ExpandCircle.Expanded .Bar {
    display: none;
}

.ExpandCircle.Expanded,
.ExpandCircle.Expanded .Line {
    opacity: 1;
    border-color: #005DFF;
}

.BngSelectSearch .OptionGroup .HideBorder {
    position: absolute;
    left: -4px;
}

.BngSelectSearch .OptionGroup .HideBorder .Circle {
    box-sizing: border-box;
    height: 7px;
    width: 7px;
    border: 1px solid #005DFF;
    background-color: #005DFF;
    border-radius: 8px;
}

.BngSelectSearch .OptionGroup .HideBorder .WhiteBox {
    width: 6px;
    height: 16px;
    background-color: white;
    position: absolute;
}

.BngSelectSearch.Disabled,
.BngSelectSearch.Disabled .OptionPreview,
.BngSelectSearch.Disabled .OptionPreview > label {
    filter: brightness(0.97);
    cursor: not-allowed !important;
}

.BngSelectSearch .SearchContainer {
    position: relative;
}

.BngSelectSearch .SearchContainer .BngIconButton {
    position: absolute;
    top: 8px;
    right: 40px;
}

.BngSelectDropdownPopper {
    z-index: 999999;
}

.BngSelectDropdownPopper-outside-overlay {
    z-index: 999998;
}

.BngSelectDropdownPopper .BngSelectSearch {
    z-index: 321055;
}

.BngSelectDropdownPopper .BngSelectDropdown {
    display: flex;
    flex-direction: column;
}

.BngSelectDropdownPopper .BngSelectSearch.Invert .BngSelectDropdown {
    flex-direction: column-reverse;
}

.BngSelectDropdownPopper .BngSelectSearch.Invert .BngSelectDropdown .bng-search {
    border-top: 1px solid #ccc;
    border-bottom: none;
}

.BngSelectDropdownPopper .Option.EmptyOpt {
    white-space: normal;
}

.BngSelectSearch .Option .selectSearchOptCheckbox {
    min-height: 16px;
}