:root {
  /*cockpit header expand/shrink animation*/
  --expand-header-transition: 1s cubic-bezier(0, 1, 1, 1);
  --blue-default: #005dff;
  --gray-default: #E4E4E4;
  --light-gray-default: #F6F6F6;
  --dark-gray-title: #333333;
  --gray-description: #555555;
  --green: #00A355;
  --red: #e23d3d;
  --warning-color: #ff9f09;
  --navbar-height: 48px;
  --breadcrumb-height: 36px;
  --background-scroll-shadow: linear-gradient(
          white 30%,
          rgba(255, 255, 255, 0)
  ) no-repeat local center top / 100% 40px,
  linear-gradient(
          rgba(255, 255, 255, 0),
          white 70%
  ) no-repeat local center bottom / 100% 40px,
  radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
  ) no-repeat scroll center top / 100% 40px,
  radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
  ) no-repeat scroll center bottom / 100% 40px
}
